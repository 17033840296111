import {Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from "reactstrap";
import React, {Fragment, useState} from "react";
import Select from "react-select";
import Switch from "react-switch";
import {string} from "yup";
import {useSelector} from "react-redux";

export const PrimaryDetails = (props) => {
    const {sellerState,errors,register,trigger,setValue,getValues,isPremium,setIsPremium,dialCode}=props;
    const [dialEditCode, setDialEditCode] = useState(dialCode);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    const defaultValue = sellerState.settings.locations.find((location) => {
        return location.id == getValues('location');
    }) || null;

    const handleIsPremiumChange = (checked) => {
        setIsPremium(checked);
        setValue('isPremium',checked)
    }
    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Legal Name<span style={{color:"red"}}>*</span></Label>
                        <input name="name" type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`} placeholder="City Goddess Ltd" {...register('name')}  />
                        <div className="invalid-feedback">{errors.name?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Shop Name<span style={{color:"red"}}>*</span></Label>
                        <input name="shopName" type="text" className={`form-control ${errors.shopName ? 'is-invalid' : ''}`} placeholder="City Goddess" {...register('shopName')}  />
                        <div className="invalid-feedback">{errors.shopName?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Country<span style={{color:"red"}}>*</span></Label>
                        <Select placeholder="United Kingdom" getOptionLabel ={(option)=>option.name} value={getValues('pdCountry')}
                                getOptionValue ={(option)=>option.id} {...register("pdCountry")} onChange={(e)=>{
                            setValue('pdCountry',e);
                            trigger('pdCountry');
                            setDialEditCode(e.dial_code);
                        }} className={` ${errors.pdCountry ? 'is-invalid' : ''}`} options={sellerState.settings.countries} />
                        <div className="invalid-feedback">{errors.pdCountry?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">City</Label>
                        <input name="pdCity" type="text" className={`form-control ${errors.pdCity ? 'is-invalid' : ''}`} placeholder="Liverpool" {...register('pdCity')}  />
                        <div className="invalid-feedback">{errors.pdCity?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="form-label">Email<span style={{color:"red"}}>*</span></Label>
                        <input name="email" type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} placeholder="john.doe@citygoddess.co.uk" {...register('email')}  />
                        <div className="invalid-feedback">{errors.shopName?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="form-label">Mobile/Telephone Number<span style={{color:"red"}}>*</span></Label>
                        <FormGroup className="input-group">
                            <span className="input-group-text">{dialEditCode}</span>
                            <input name="phone" type="number"  onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                            }
                                                                        }}
                                    className={`form-control ${errors.number ? 'is-invalid' : ''}`}
                                    placeholder="Phone Number" {...register('number')}  />
                            <div className="invalid-feedback">{errors.number?.message}</div>
                        </FormGroup>
                    </FormGroup>
                </Col>
                {((currentUser.role.name!=="Seller")) &&  <Col md={2}>
                    <FormGroup>
                        <Label className="form-label">Commission<span style={{color:"red"}}>*</span></Label>
                        <InputGroup>
                            <input name="commission" type="text" className={`form-control ${errors.commission ? 'is-invalid' : ''}`} placeholder="10" {...register('commission')}  />
                            <InputGroupAddon addonType="append"><InputGroupText>{"%"}</InputGroupText></InputGroupAddon>
                            <div className="invalid-feedback">{errors.commission?.message}</div>
                        </InputGroup>
                    </FormGroup>
                </Col>}
                <Col md={2}>
                    <FormGroup>
                        <Label className="form-label">Is Premium</Label>
                        <div className="form-control pl-0 border-0">
                            <Switch uncheckedIcon={false} checkedIcon={false} onChange={handleIsPremiumChange} checked={isPremium} className="react-switch" />
                        </div>
                        <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.isPremium?.message}</div>
                    </FormGroup>
                </Col>
                {((currentUser.role.name!=="Seller")) && <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Seller ID</Label>
                        <input name="sellerId" type="text" className={`form-control ${errors.sellerId ? 'is-invalid' : ''}`} placeholder="1234" {...register('sellerId')}  />
                        <div className="invalid-feedback">{errors.sellerId?.message}</div>
                    </FormGroup>
                </Col>}
                {((currentUser.role.name!=="Seller")) && <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Location<span style={{color:"red"}}>*</span></Label>
                        <Select
                            placeholder="Location"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            options={sellerState.settings.locations}
                            value={defaultValue} // Set the value using the ID or null if no value is set
                            // {...register("location")}
                            onChange={(selectedOption) => {
                                setValue('location', selectedOption ? selectedOption.id : ''); // Update value
                                trigger('location'); // Manually trigger validation
                            }}
                            className={`${errors.location ? 'is-invalid' : ''}`} // Apply error class if validation fails
                        />
                        <div className="invalid-feedback">{errors.location?.message}</div>
                    </FormGroup>
                </Col>}
            </Row>
        </Fragment>
    );
};