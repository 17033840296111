import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";


export const ViewReturnWarehouse = (props) => {
    const {sellerDetails}=props;
    return (
        <Fragment>
            <Col sm="4">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-warning">Returns Warehouse Address</div>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Address</Label>
                                    <p>{sellerDetails.returnsFirstAddress}</p>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">City/Town</Label>
                                    <p>{sellerDetails.returnsCity}</p>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Country</Label>
                                    <p>{sellerDetails.returnsCountry.code && <i className={sellerDetails.returnsCountry?"mr-1 flag-icon flag-icon-"+sellerDetails.returnsCountry.code.toLowerCase():""}></i>}{sellerDetails.returnsCountry.name}</p>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Postcode</Label>
                                    <p>{sellerDetails.returnsPostcode}</p>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};