import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import {
    getFinancesSummarySettingApi, 
    getFinancesSummaryApi 
} from "../../../../services/report";


export const getFinancesSummary = createAsyncThunk('report/getFinancesSummary', async (data, { dispatch }) => {
    const response = await getFinancesSummaryApi(data);
    dispatch(setFinancesSummary(response.response));
    return response.response.data;
});

export const getFinancesSummarySetting = createAsyncThunk('report/getFinancesSummarySetting', async (data, { dispatch, }) => {
    const response = await getFinancesSummarySettingApi(data);
    dispatch(setSettings(response.response))
    return response.response;
});


const financesSummaryAdapter = createEntityAdapter({});

export const { selectAll: selectFinancesSummary, selectById: selectFinancesSummaryById } = financesSummaryAdapter.getSelectors(
    state => state.report
);

const financesSummarySlice = createSlice({
    name: 'financesSummary',
    initialState: financesSummaryAdapter.getInitialState({
        id: null,
        settings: {
            partners: [],
        },
        filters: {
            startDate: null,
            endDate: null,
            partner: null
        },
        salesSummary: {
            gross_sales: 0,
            discount: 0,
            shipping_charges: 0,
            total_tax: 0,
            total_return: 0,
            net_sales: 0,
            total_sales: 0,
            total_cost: 0,
            gross_profit: 0,
            is_show_commission: false,
            commission_percentage: 0,
            commission_amount: 0,
        },
    }),
    reducers: {
        setSettings: (state, action) => {
            state.settings.partners = action.payload.partners;
        },
        setFilter: (state, action) => {
            state.filters = action.payload;
        },
        setFinancesSummary: (state, action) => {
            state.salesSummary = action.payload.sales_summary;
        },
    },
    extraReducers: {
        // [getFinancesSummary.fulfilled]: financesSummaryAdapter.setAll
    }
});

export const {
    setSettings,
    setFilter,
    setFinancesSummary,
} = financesSummarySlice.actions;

export default financesSummarySlice.reducer;