import React from 'react';

import {
    formatter, formatterDecimal
} from "../../../../utils/common";

const GrossProfitSummary = (props) => {

    const {
        salesSummary,
        loading
    } = props;

    return (
        <div className="card">
            {loading && (
                <div className="card-loading">
                    <i className="fa fa-spinner fa-lg spinner" />
                </div>
            )}

            <div className="card-body">
                <h5 className="card-title">Gross profit</h5>
                <div className="d-flex justify-content-between mb-2">
                    <a href="#" className="text-decoration-none">Net sales</a>
                    <span>{formatter.format(salesSummary.net_sales)}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <span>Cost of goods sold</span>
                    <span>({formatter.format(salesSummary.total_cost)})</span>
                </div>

                {salesSummary.is_show_commission && (
                    <div className="d-flex justify-content-between mb-2">
                        <span>Commission ({formatterDecimal.format(salesSummary.commission_percentage)}%)</span>
                        <span>({formatter.format(salesSummary.commission_amount)})</span>
                    </div>
                )}

                <hr />
                <div className="d-flex justify-content-between fw-bold">
                    <a href="#" className="text-decoration-none">Gross profit</a>
                    <span>{formatter.format(salesSummary.gross_profit)}</span>
                </div>
            </div>
        </div>
    );
};

export default GrossProfitSummary;
