import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import moment from "moment";

import {
	Card,
	Col,
	Container,
	Row
} from "reactstrap";

import DatePicker from "../../../share-components/ui/DateRangePicker";
import Breadcrumb from "../../../layout/breadcrumb";
import withReducer from "../../../store/withReducer";
import reportReducer from "../index";

import SalesSummary from './content/SalesSummary';
import GrossProfitSummary from './content/GrossProfitSummary';
import CommissionSummary from './content/CommissionSummary';

import {
	setFilter,
	getFinancesSummarySetting,
	getFinancesSummary
} from "./store/financesSummarySlice";

import "./styles.scss";

const FinancesSummary = (props) => {

	const financesSummaryState = useSelector(
		({ report }) => report.financesSummary
	);

	const endDateObj = new Date();
	const startDateObj = new Date(endDateObj); // Create a new Date object from endDateObj
	startDateObj.setDate(endDateObj.getDate() - 30); // Subtract 30 days from today

	const [dateRange, setDateRange] = useState([startDateObj, endDateObj]);
	const [startDate, endDate] = dateRange;

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);

	const dataMenu = [
		{
			type: 0,
			text: "Finances Summary",
		},
	];

	const afterToday = () => {
		return (date) => date.getTime() > new Date().getTime();
	};

	useEffect(() => {
		dispatch(getFinancesSummarySetting());
	}, [dispatch]);

	const getFinancesSummaryData = () => {
		setLoading(true);
		const filters = financesSummaryState.filters;
		dispatch(
			getFinancesSummary({
				start_date: moment(startDate).format("YYYY-MM-DD"),
				end_date: moment(endDate).format("YYYY-MM-DD"),
				partner: filters.partner?.id,
			})
		).then((res) => {
			if (!res.error) {
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		getFinancesSummaryData();
	}, [dispatch, startDate, endDate, financesSummaryState.filters.partner])


	return (
		<Fragment>
			<Breadcrumb title="Finances Summary" data={dataMenu} />
			<Container fluid={true} className="finances-summary-page">
				<Card>
					<Row>
						<Col md="4">
							<div className="dashboard-datepicker p-3">
								<DatePicker
									dateRange={dateRange}
									dateRangeChange={(date) => {
										console.log('change', date);
										setDateRange(date);
									}}
									defaultValue={[endDateObj, startDateObj]}
									disabledDate={afterToday()}
								/>
							</div>
						</Col>
					</Row>
				</Card>

				<Row>
					<Col md="4" className="pb-4">
						<Select
							className="select2-filter"
							menuPortalTarget={document.body}
							styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
							value={financesSummaryState.filters.partner}
							getOptionValue={(option) => option.id}
							getOptionLabel={(option) => option.name}
							onChange={(e) => {
								dispatch(setFilter({
									...financesSummaryState.filters,
									partner: e
								}));
							}}
							isClearable={true}
							placeholder="Partners"
							components={{
								IndicatorSeparator: () => null
							}}
							options={financesSummaryState.settings.partners}
						/>
					</Col>
				</Row>

				<Row>
					<Col md="4">
						<SalesSummary salesSummary={financesSummaryState.salesSummary} loading={loading} />
					</Col>
					<Col md="4">
						<GrossProfitSummary salesSummary={financesSummaryState.salesSummary} loading={loading} />
					</Col>

					{financesSummaryState.salesSummary.is_show_commission && (
						<Col md="4">
							<CommissionSummary salesSummary={financesSummaryState.salesSummary} loading={loading} />
						</Col>
					)}
				</Row>
			</Container>
		</Fragment>
	);
};

export default withReducer([{ report: reportReducer }])(FinancesSummary);
