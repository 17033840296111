import React from 'react';

import {
    formatter
} from "../../../../utils/common";

const SalesSummary = (props) => {

    const {
        salesSummary,
        loading
    } = props;


    return (
        <div className="card">
            {loading && (
                <div className="card-loading">
                    <i className="fa fa-spinner fa-lg spinner" />
                </div>
            )}

            <div className="card-body">
                <h5 className="card-title">Sales</h5>
                <div className="d-flex justify-content-between mb-2">
                    <a href="#" className="text-decoration-none">Gross Sales</a>
                    <span>{formatter.format(salesSummary.gross_sales)}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <a href="#" className="text-decoration-none">Discounts</a>
                    <span>({formatter.format(salesSummary.discount)})</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <a href="#" className="text-decoration-none">Returns</a>
                    <span>({formatter.format(salesSummary.total_return)})</span>
                </div>
                <hr />
                <div className="d-flex justify-content-between mb-2">
                    <span>Net Sales</span>
                    <span>{formatter.format(salesSummary.net_sales)}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <span>Shipping</span>
                    <span>{formatter.format(salesSummary.shipping_charges)}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <span>Taxes</span>
                    <span>{formatter.format(salesSummary.total_tax)}</span>
                </div>
                <hr />
                <div className="d-flex justify-content-between fw-bold">
                    <span>Total Sales</span>
                    <span>{formatter.format(salesSummary.total_sales)}</span>
                </div>
            </div>
        </div>
    );
};

export default SalesSummary;