import React, { Fragment, useEffect, useRef, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import { toast } from "react-toastify";

import JqxTable from "../../../../../share-components/table/JqxTable";
import { jqx } from "../../../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid";


const AlterationRequestJqx = (props) => {
    const { orderState, getGridJqx } = props;
    let gridRef = useRef(null);
    // const reasonSources = (reasonData) => {
    //     return {
    //         datatype: 'array',
    //         datafields: [
    //             { name: 'id', type: 'string' },
    //             { name: 'name', type: 'string' }
    //         ],
    //         localdata: reasonData,
    //     }
    // }

    // const reasonAdapter = new jqx.dataAdapter(reasonSources(orderState.alterationRequestData.status_items), { autoBind: true });

    const dataFields = [
        { name: 'id', type: 'id' },
        { name: 'order_line_item_id', type: 'number' },
        { name: 'line_item_id', type: 'number' },
        { name: 'price', type: 'number' },
        { name: 'product_name', type: 'string' },
        { name: 'length', type: 'string' },
        { name: 'image', type: 'string' },
        { name: 'sku', type: 'string' },
        { name: 'brand_name', type: 'string' },
        { name: 'colour', type: 'string' },
        { name: 'size', type: 'string' },
        { name: 'request_quantity', type: 'number' },
        { name: 'ordered_quantity', type: 'number' },
        { name: 'status_id', type: 'number' },
        { name: 'status', type: 'string' },
        // { name: 'reason_id', type: 'string' },
        // { name: 'reason', value: 'reason_id', values: { source: reasonAdapter.records, value: 'id', name: 'name' } },
        { name: 'is_received', type: 'boolean' },
        { name: 'is_completed', type: 'boolean' },
        { name: 'is_decline', type: 'boolean' },
    ];

    // eslint-disable-next-line no-shadow
    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;
            getGridJqx(jqx);
            // jqx.selectallrows();
            setTimeout(
                () => {
                    if (gridRef) {
                        const myEle = document.getElementById(gridRef._id);
                        if (myEle) {
                            gridRef.updatebounddata();
                        }
                    }

                    // myGrid.updatebounddata();
                },
                1000
            );
        }
    };

    let columns = [
        { datafield: 'id', hidden: true },
        { datafield: 'order_line_item_id', hidden: true },
        { datafield: 'line_item_id', hidden: true },
        { datafield: 'price', hidden: true },
        {
            text: 'Product Name',
            datafield: 'product_name',
            editable: false,
            align: 'center',
            cellsalign: 'left',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                const productDetails = orderState.alterationRequestData.edit_lists[row];
                const html = ReactDOMServer.renderToString(
                    <div className="px-3">
                        {productDetails && <div className="item-row">
                            <div className="item-image-col">
                                <img className="img-fluid" src={productDetails.image} alt="" />
                            </div>
                            <div className="item-content-col">
                                <p className="text-primary font-weight-bold">{value}</p>
                                <p>{productDetails.colour}/{productDetails.size}</p>
                                <p>{productDetails.sku}</p>
                                <p>{productDetails.brand_name}</p>
                                <p><b>Note:</b> {productDetails.note}</p>
                            </div>
                        </div>}
                    </div>
                );
                return html;
            },
            cellbeginedit(row, datafield, columntype) { },
        },
        {
            text: 'Length',
            datafield: 'length',
            width: '10%',
            editable: false,
            align: 'center',
            cellsalign: 'center',
            cellbeginedit(row, datafield, columntype) { },
        },
        {
            text: 'Requested Qty',
            datafield: 'request_quantity',
            width: '10%',
            editable: true,
            align: 'center',
            cellsalign: 'center',
            cellbeginedit(row, datafield, columntype) { },
            validation(cell, value) {
                let ordered_quantity = Number(gridRef.getcellvalue(cell.row, 'ordered_quantity'));
                let request_quantity = Number(value);
                if (ordered_quantity < request_quantity) {
                    return {result: false, message: "Can't allow more than the ordered quantity."}
                }else if (request_quantity <= 0) {
                    return {result: false, message: "Can't allow less than a quantity of 1."}
                }

                return true;
            }
        },
        {
            text: 'Ordered Qty',
            datafield: 'ordered_quantity',
            width: '10%',
            editable: false,
            align: 'center',
            cellsalign: 'center',
            cellbeginedit(row, datafield, columntype) { },
        },
        // {
        //     text: '<strong>Reason</strong>',
        //     datafield: 'reasonID',
        //     displayfield: 'reason',
        //     width: '20%',
        //     align: 'center',
        //     columntype: 'dropdownlist',
        //     editable: true,
        //     cellbeginedit(row, datafield, columntype, val) {
        //         let found = 0;
        //         gridRef.getselectedrowindexes().forEach(element => element === row ? found++ : "");
        //         return (found > 0);
        //     },
        //     createeditor: (row, value, editor) => {
        //         console.log(orderState.settings);
        //         editor.jqxDropDownList({ height: 75, source: reasonAdapter, displayMember: 'name', valueMember: 'id' });
        //         // editor.jqxDropDownList({clearSelection:true });
        //     },
        //     validation(cell, value) {

        //         const reasons = orderState.settings.returnReasons
        //         if (value) {
        //             let selectedReason = reasons.find(item => item.id === Number(value.value));
        //             gridRef.setcellvalue(cell.row, 'type', selectedReason.is_received === "Yes");
        //             if (orderState.alterationRequestData.data.is_declined) {
        //                 gridRef.setcellvalue(cell.row, 'type', false);
        //             }
        //         }
        //         return true;
        //     }
        // },
        {
            text: 'Status',
            datafield: 'status',
            width: '12%',
            editable: false,
            align: 'center',
            cellsalign: 'center',
            cellbeginedit(row, datafield, columntype) { },
        },
        {
            text: 'Received',
            datafield: 'is_received',
            editable: true,
            width: '8%',
            align: 'center',
            cellsalign: 'right',
            columntype: 'checkbox',
            cellbeginedit(row, datafield, columntype, val) {
                // Define logic to disable checkbox for specific rows
                const statusId = gridRef.getcellvalue(row, 'status_id');
                const isDeclined = gridRef.getcellvalue(row, 'is_decline');
                const isCompleted = gridRef.getcellvalue(row, 'is_completed');
                const rowSelected = gridRef.getselectedrowindexes().includes(row);
                
                // Disable the checkbox if the row is comleted or declined or not selected
                return rowSelected && statusId == 1 && !isCompleted && !isDeclined;
            },
            cellendedit(row, datafield, columntype, oldvalue, value) {

            }
        },
        {
            text: 'Completed',
            datafield: 'is_completed',
            editable: true,
            width: '8%',
            align: 'center',
            cellsalign: 'right',
            columntype: 'checkbox',
            cellbeginedit(row, datafield, columntype, val) {
                // Define logic to disable checkbox for specific rows
                const statusId = gridRef.getcellvalue(row, 'status_id');
                const isDeclined = gridRef.getcellvalue(row, 'is_decline');
                const isReceived = gridRef.getcellvalue(row, 'is_received');
                const rowSelected = gridRef.getselectedrowindexes().includes(row);
                
                // Disable the checkbox if the row is comleted or declined or not selected
                return rowSelected && (statusId == 1 || statusId == 2) && !isReceived && !isDeclined;
            },
            cellendedit(row, datafield, columntype, oldvalue, value) {

            }
        },
        {
            text: 'Declined',
            datafield: 'is_decline',
            editable: true,
            width: '8%',
            align: 'center',
            cellsalign: 'right',
            columntype: 'checkbox',
            cellbeginedit(row, datafield, columntype, val) {
                // Define logic to disable checkbox for specific rows
                const statusId = gridRef.getcellvalue(row, 'status_id');
                const isReceived = gridRef.getcellvalue(row, 'is_received');
                const isCompleted = gridRef.getcellvalue(row, 'is_completed');
                const rowSelected = gridRef.getselectedrowindexes().includes(row);
                
                // Disable the checkbox if the row is comleted or declined or not selected
                return rowSelected && (statusId == 1 || statusId == 2) && !isCompleted && !isReceived;
            },
            cellendedit(row, datafield, columntype, oldvalue, value) {

            }
        }
    ];
    // if(orderState.data.is_return){
    //     columns=[
    //         ...columns,
    //         {
    //             text: 'Declined',
    //             datafield: 'is_decline',
    //             editable: true,
    //             width: '8%',
    //             align: 'center',
    //             cellsalign: 'right',
    //             columntype: 'checkbox',
    //             cellbeginedit(row, datafield, columntype, val) {
    //                 let found=0;
    //                 gridRef.getselectedrowindexes().forEach(element => element===row?found++:"");
    //                 return (found>0) && (!gridRef.getcellvalue(row, 'is_received'))
    //             },
    //             cellendedit(row, datafield, columntype, oldvalue, value) {

    //             }
    //         }

    //     ]
    // }

    const onSelectRow = event => {
        const gridInformation = gridRef.getdatainformation();
        // for (let i = 0; i < gridInformation.rowscount; i++) {
        //     gridRef.setcellvalue(i, 'reason', '');
        //     gridRef.setcellvalue(i, 'reasonID', "");
        // }
    };

    const onRowunselect = event => {
        // gridRef.setcellvalue(event.args.rowindex, 'reason', '');
        // gridRef.setcellvalue(event.args.rowindex, 'reason_id', null);
    };

    return (
        <Fragment>
            {orderState.alterationRequestData.edit_lists.length > 0 && <JqxTable
                datafields={dataFields}
                autorowheight={true}
                pageable={false}
                autoheight={true}
                columns={columns}
                data={orderState.alterationRequestData.edit_lists ? orderState.alterationRequestData.edit_lists : null}
                myGrid={gridRef}
                isDelete={false}
                disableCreateRow
                getJqxRef={getJqxRef}
                onRowselect={onSelectRow}
                onRowunselect={onRowunselect}
                selectionmode={'checkbox'}
                enableBrowserSelection={true}
            />}
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.orderState) == JSON.stringify(nextProps.orderState);
}

export default React.memo(AlterationRequestJqx, areEqual);