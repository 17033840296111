import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Badge, Col, FormGroup, Input, Label, Row} from "reactstrap";
import Select from 'react-select'
import {SellerImageUploader} from "../sellerImageUploader";
import ImageUpload from "image-upload-react";
import {useSelector} from "react-redux";
import { Tooltip } from 'reactstrap';


const BrandForm = (props) => {
    const { errors, control, userRoles, register,setValue,trigger,getValues,brands } = props;
    const [dialCode, setDialCode] = useState('');
    const sellerState = useSelector(({ seller }) => seller.seller);
    const [selectedImage,setSelectedImage]=useState(getValues('imageFile'));
    const [selectedImageLogo,setSelectedImageLogo]=useState(getValues('imageFileLogo'));
    const imageUploaderRef= useRef(null);
    const imageUploaderRefLogo= useRef(null);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

    const handleDeleteImage = () => {
        setValue('image', null);
        setValue('imageFile', null);
        setSelectedImage(null);
        const input = document.getElementById("hiddenFileInput1");
        input.value = "true";
    }

    const handleDeleteLogoImage = () => {
        setValue('logoImage', null);
        setValue('imageFileLogo', null);
        setSelectedImageLogo(null);
        const input = document.getElementById("hiddenFileInput2");
        input.value = "true";
    }

    return (
        <Fragment>
            <Row> 
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Brand Name<span style={{color:"red"}}>*</span></Label>
                        <input name="brand" type="text" {...register('brand')} className={`form-control ${errors.brand ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.brand?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Shopify Collection ID</Label>
                        <input name="collectionId" type="text" {...register('collectionId')} className={`form-control ${errors.collectionId ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.collectionId?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6} style={{zIndex:"1000"}}>
                     <FormGroup>
                        <Label className="form-label">Country<span style={{color:"red"}}>*</span></Label>
                        <Select placeholder="United Kingdom" getOptionLabel ={(option)=>option.name} defaultValue={getValues('country')}
                                getOptionValue ={(option)=>option.id} {...register("country")} onChange={(e)=>{
                            setValue('country',e);
                            setValue('dialCode',e.dial_code);
                            trigger('country');
                        }} className={` ${errors.country ? 'is-invalid' : ''}`} options={sellerState.settings?sellerState.settings.countries:null} />
                        <div className="invalid-feedback">{errors.country?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">City<span style={{color:"red"}}>*</span></Label>
                        <input name="city" type="text" {...register('city')} className={`form-control ${errors.city ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.city?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Mobile/Telephone number</Label>
                        <FormGroup className="input-group">
                            <span className="input-group-text">{getValues('country')?.dial_code}</span>
                            <input name="mobileNo" type="number" onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                    }
                                                }} 
                                    className={`form-control ${errors.mobileNo ? 'is-invalid' : ''}`}
                                    placeholder="Phone Number" {...register('mobileNo')}  />
                            <div className="invalid-feedback">{errors.mobileNo?.message}</div>
                        </FormGroup>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Email</Label>
                        <input name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.email?.message}</div>
                    </FormGroup>
                </Col>
                   
                <Col md={6}>
                <input name="imageDeleted" type="hidden" id="hiddenFileInput1"  />
                    <FormGroup>
                        <Label className="form-label">Banner Image</Label>
                        <span className="mt-3 mr-2 ml-1" id="TooltipExample">
                            <i aria-hidden="true" className="fa fa-exclamation-circle"></i>
                        </span>   
                        <Tooltip
                            placement= 'right'
                            isOpen={tooltipOpen}
                            target="TooltipExample"
                            toggle={toggle}
                            style={{ fontSize: '12px',maxWidth: '900px' }}
                        >
                            please upload  brand image with width:1920px, height:768px
                        </Tooltip>
                        <div className="text-black-50">{errors.image?.message}</div>
                        <div className="text-black-50">{errors.imageFile?.message}</div>
                        <ImageUpload
                            handleImageSelect={e => {
                                setValue('image',e.target.files[0]);
                                setValue('imageFile',URL.createObjectURL(e.target.files[0]));
                                setSelectedImage(URL.createObjectURL(e.target.files[0]));
                            }}
                            ref={imageUploaderRef}
                            imageSrc={selectedImage}
                            setImageSrc={setSelectedImage}
                            style={{
                                width: 'auto',
                                height: '200px',
                                marginTop:0,
                                backgroundColor:'#fff'
                            }}
                            deleteIcon={
                                <span className="mt-3 mr-2"  onClick={handleDeleteImage}>
                                    <i aria-hidden="true" className="fa fa-times-circle fa-2x"></i>
                                </span>
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                <input name="logoImageDeleted" type="hidden" id="hiddenFileInput2"  />
                    <FormGroup>
                        <Label className="form-label">Brand shop Logo</Label>
                        <span className="mt-3 mr-2 ml-1" id="TooltipExample2">
                            <i aria-hidden="true" className="fa fa-exclamation-circle"></i>
                        </span>
                        <Tooltip
                            placement= 'right'
                            isOpen={tooltipOpen1}
                            target="TooltipExample2"
                            toggle={toggle1}
                            style={{ fontSize: '12px',maxWidth: '900px' }}
                        >
                            please upload logo image with width:420px, height:120px
                        </Tooltip>
                        <div className="text-black-50">{errors.logoImage?.message}</div>
                        <div className="text-black-50">{errors.imageFileLogo?.message}</div>
                        <ImageUpload
                            handleImageSelect={e => {
                                setValue('logoImage',e.target.files[0]);
                                setValue('imageFileLogo',URL.createObjectURL(e.target.files[0]));
                                setSelectedImageLogo(URL.createObjectURL(e.target.files[0]));
                            }}
                            ref={imageUploaderRefLogo}
                            imageSrc={selectedImageLogo}
                            setImageSrc={setSelectedImageLogo}
                            style={{
                                width: 'auto',
                                height: '200px',
                                marginTop:0,
                                backgroundColor:'#fff'
                            }}
                            deleteIcon={
                                <span className="mt-3 mr-2"  onClick={handleDeleteLogoImage}>
                                    <i aria-hidden="true" className="fa fa-times-circle fa-2x"></i>
                                </span>
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>


        </Fragment>
    );
}

export default BrandForm;