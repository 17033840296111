import {Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";
import Select from "react-select";

export const ReturnsWarehouseAddress = (props) => {
    const {sellerState,errors,register,trigger,setValue,getValues}=props;
    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Address<span style={{color:"red"}}>*</span></Label>
                        <input name="returnsFirstAddress" type="text" className={`form-control ${errors.returnsFirstAddress ? 'is-invalid' : ''}`} placeholder="Enter the address" {...register('returnsFirstAddress')}  />
                        <div className="invalid-feedback">{errors.returnsFirstAddress?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">City/Town<span style={{color:"red"}}>*</span></Label>
                        <input name="returnsCity" type="text" className={`form-control ${errors.returnsCity ? 'is-invalid' : ''}`} placeholder="Enter the city / town" {...register('returnsCity')}  />
                        <div className="invalid-feedback">{errors.returnsCity?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Country<span style={{color:"red"}}>*</span></Label>
                        <Select placeholder="Select a country" getOptionLabel ={(option)=>option.name} value={getValues('returnsCountry')}
                                getOptionValue ={(option)=>option.id} {...register("returnsCountry")} onChange={(e)=>{
                            setValue('returnsCountry',e);
                            trigger('returnsCountry');
                        }} className={` ${errors.returnsCountry ? 'is-invalid' : ''}`} options={sellerState.settings.countries} />
                        <div className="invalid-feedback">{errors.returnsCountry?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Postcode<span style={{color:"red"}}>*</span></Label>
                        <input name="returnsPostcode" type="text" className={`form-control ${errors.returnsPostcode ? 'is-invalid' : ''}`} placeholder="Enter the postcode" {...register('returnsPostcode')}  />
                        <div className="invalid-feedback">{errors.returnsPostcode?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    );
};