import {Button, Card, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {
    closeBrandDialog, deleteSellerBrands,
    getSellerBrands,
    openBrandDialog,
    updateSellerBrand,
    updateSellerImg, openSellerAllocateDialog, closeSellerAllocateDialog, UpdateSellerData
} from "../../store/sellerSlice";
import BrandForm from "./modal-form/brandForm";
import {useDispatch} from "react-redux";
import SweetAlert from 'sweetalert2';
import {toast} from "react-toastify";
import './brandDetailStyles.css';
import AllocateSeller from "./modal-form/allocateSeller";

const defaultValues = {
    brand: '',
    collectionId:'',
    email:'',
    city:'',
    country:'',
    mobileNo:'',
    image: '',
    imageFile: '',
    logoImage:'',
    imageFileLogo:'',
    dialCode:'',
    imageDeleted:'',
    logoImageDeleted:''
}
const defaultValuesSeller = {
    seller_id: '',
    brand_ids: [],
}

/**
 * Form Validation Schema
 */

const phoneRegExp =/^[0-9]{10}$/
yup.addMethod(yup.string, "customPhoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if(value){
            return  value.match(phoneRegExp)?true:false;
        }
        return  false;
    });
});

const schema = yup.object().shape({
    brand: yup.string().required('Brand is required field').typeError('Brand is a required field'),
    city: yup.string().required('city is required field').typeError('city is a required field'),
    country: yup.object().required('Country is required field').typeError('Please select Country'),
    mobileNo: yup.string().nullable().when({
        is: (val) => val,
        then: yup.string().customPhoneValidation('You must enter valid phone number'),
      }),
    email: yup.string().nullable().email(),
    
});


export const BrandDetails = (props) => {
    const {sellerState}=props;

    const { handleSubmit, formState, reset, control, register,setValue ,trigger,getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {
        handleSubmit: handleSubmitSeller,
        formState: formStateSeller,
        reset: resetSeller,
        register: registerSeller,
        setValue: setValueSeller,
        getValues: getValuesSeller,
        trigger: triggerSeller,
    } = useForm({
        mode: 'onChange',
        defaultValues: defaultValuesSeller,
        // resolver: yupResolver(schemaHolidayOffer)
    });

    const { errors } = formState;
    const { errors: errorsSeller } = formStateSeller;
    const dispatch = useDispatch();
    const [btnDisabled,setBtnDisabled]=useState(false);
    const [btnDisabledSeller,setBtnDisabledSeller]=useState(false);
    const onButtonClick = () =>{
        openDialog();
    };
  
    const onSubmitFormSeller = (dialog, data) => {
        if(data){
            setBtnDisabledSeller(true);
            data.seller_id=data.seller_id?data.seller_id.value:'';
            dispatch(UpdateSellerData({...data})).then(res => {
                if (!res.error) {
                    setBtnDisabledSeller(false);
                    closeSellerDialog();
                }else{
                    toast.error(res.error.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
           
        }
    };
    const onSubmitForm = (dialog, data) => {
        if(data){
            const formData = new FormData();
            formData.append('id', sellerState.id);
            formData.append('old_id', sellerState.brandDialog.data.oldId?sellerState.brandDialog.data.oldId:null);
            formData.append('brand', data.brand);
            formData.append('collectionId', data.collectionId? data.collectionId:"");
            formData.append('email', data.email? data.email:"");
            formData.append('city', data.city);
            formData.append('country', data.country?.id);
            formData.append('mobileNo', data.mobileNo?data.mobileNo:"");
            formData.append('image', data.image ? data.image : "");
            if (document.getElementById("hiddenFileInput1").value === "true") {
                formData.append('imageDeleted', true);
            } else {
                formData.append('imageDeleted', false);
            }
            
            formData.append('logoImage', data.logoImage ? data.logoImage : "");
            if (document.getElementById("hiddenFileInput2").value === "true") {
                formData.append('logoImageDeleted', true);
            } else {
                formData.append('logoImageDeleted', false);
            }
           
            setBtnDisabled(true);
            dispatch(updateSellerBrand(formData)).then(res => {
            setBtnDisabled(false);
                if (!res.error) {
                    dispatch(getSellerBrands(sellerState.id));
                    closeDialog();
                }
            });
        }
    };
    const openDialog = (param=null)=>{
        dispatch(openBrandDialog(param));
    }
    const deleteBrand = (item)=>{
        SweetAlert.fire({
            title: `Delete - ${item.name}`,
            text: "Once deleted, you will not be able to recover brand!",
            icon: 'warning',
            showCancelButton: true,
            animation: false,
            confirmButtonText: 'Yes',
            cancelButtonText: 'cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    const set={
                        id:sellerState.id,
                        brand_id:item.id
                    }
                    dispatch(deleteSellerBrands(set)).then(res => {
                        if (!res.error) {
                            toast.success("Brand has been deleted!", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    });

                }
            })
    }

    const closeDialog = () =>{
        reset(defaultValues);
        dispatch(closeBrandDialog());
    };

    const closeSellerDialog = () =>{
        reset(defaultValues);
        dispatch(closeSellerAllocateDialog());
    };

    const isChecked = true;
    const [isButtonEnabled, setButtonEnabled] = useState(false);
    const handleCheckId = (checkedSellerId) => {
    
        const checkIds = getValuesSeller('brand_ids');
        let selectedIds = checkIds.includes(checkedSellerId)
        ? checkIds.filter((id) => id !== checkedSellerId) 
        : [...checkIds, checkedSellerId]; 
        setValueSeller('brand_ids', selectedIds);

        const isButtonEnabled = selectedIds.length > 0;
        setButtonEnabled(isButtonEnabled);
      };

    return (
        <Fragment>
            <Row>
                <Col>
                {sellerState.id == 0 && (
                    
                    <Button color="#0069d9" className="btn btn-primary float-sm-right p-3"
                    onClick={() => {
                        dispatch(openSellerAllocateDialog());
                    }}
                    disabled={!isButtonEnabled}
                    >Allocate Seller</Button>
                )}
                </Col>
            </Row>
           
            <Row className="mt-3 gridRow">
                {sellerState.sellerBrands? sellerState.sellerBrands.map((item, i) =>
                    <div className="col-md-3" key={i}>
                        <Card>
                            <div className="product-box border">
                                <div className="product-img">
                                    <img style={{height:'150px',width:'15vw',objectFit:'cover'}} src={item.image_path} alt="" />
                                    <div className="product-hover">
                                        <ul>
                                            <li onClick={() => openDialog(item)}>
                                                <Button type="button" color="default" >
                                                    <i className="icon-eye"></i>
                                                </Button>
                                            </li>
                                            <li onClick={() => deleteBrand(item)}>
                                                {/*onClick={() => addWishList(create)} >*/}
                                                <Button type="button" color="default" data-al={item.product}  >
                                                    <i className="icon-close"></i>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                 <div className="product-details" style={{ display: 'flex', alignItems: 'center' }}>
                                    <h4 className="font-primary" style={{ marginRight: '10px' }}>{item.brand}</h4>
                                    { sellerState.id == 0 && isChecked && (
                                        <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            const checkId = item.id;
                                            handleCheckId(checkId);
                                        }}
                                        />
                                    )}
                                </div>
                            </div>
                        </Card>
                    </div>
                ) : ''}
                <div className="col-md-3">
                    <Card className="product-box add_brand_btn" onClick={onButtonClick}>
                        <h5 className="text-center my-auto" ><i className="icofont icofont-ui-add"></i> Add Brands</h5>
                    </Card>
                </div>
            </Row>
            <ModalFormSubmit
                size="xl"
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Brand'
                selector="staff"
                loadingBtn
                btnDisabled={btnDisabled}
                dialog={sellerState.brandDialog}
                isUseSelector
                closeDialog={closeDialog}
            >
                <BrandForm control={control} errors={errors} register={register} setValue={setValue} trigger={trigger} getValues={getValues} />
            </ModalFormSubmit>

            <ModalFormSubmit
                size="md"
                defaultValues={defaultValuesSeller}
                // schema={schema}
                onSubmitForm={onSubmitFormSeller}
                reset={resetSeller}
                formState={formStateSeller}
                handleSubmit={handleSubmitSeller}
                title='Seller Allocate'
                selector="staff"
                loadingBtn
                btnDisabled={btnDisabledSeller}
                dialog={sellerState.sellerAllocateDialog}
                isUseSelector
                closeDialog={closeSellerDialog}
            >
                <AllocateSeller control={control} errors={errorsSeller} register={registerSeller} setValue={setValueSeller} trigger={triggerSeller} getValues={getValuesSeller} />
            </ModalFormSubmit>
        </Fragment>
    );
};